import { Canvas } from "@react-three/fiber";
import { OrbitControls, useGLTF } from "@react-three/drei";
import { Suspense } from "react";
import { models } from "../constants/models";

const ShibaCanvas = ({ active }) => {
  const model = useGLTF(models.shiba);
  //const light = new DirectionalLight("#FFFFFF",2);

  return (
    <div
      className="canvas-wrapper"
      style={{
        width: "100%",
        position: "relative",
        display: !active ? "none" : "",
      }}
    >
      <Canvas
        frameloop="demand"
        camera={{ position: [0, 1, -8], fov: 35 }}
        onCreated={({ camera, scene }) => {
          scene.add(camera);
        }}
      >
        <ambientLight intensity={3.7} />
        <Suspense fallback="Loading...">
          <OrbitControls
            autoRotate
            enableZoom={false}
            target={[0, 0, 0]}
            enabled={active}
          />
          <primitive
            object={model.scene}
            scale={2.7}
            position={[0, -0.5, 0]}
            rotation={[0, Math.PI, 0]}
          />
        </Suspense>
      </Canvas>
    </div>
  );
};

export default ShibaCanvas;
