import { useGLTF } from "@react-three/drei";
import { useState, useEffect } from "react";
import { TextureLoader } from "three";
import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";
import { models } from "./models";

export const useImageLoader = () => {
  const [MANIFESTO_IMAGES, setManifestoImages] = useState({});
  const [textures, setTextures] = useState({});
  const [progress, setProgress] = useState(0);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const importImages = async () => {
      const totalImages = 24;
      let totalLoaded = 0;

      const imageObject = {};
      const texts = {};

      function calcProgress(src) {
        setProgress(Math.round((totalLoaded / totalImages) * 100));
        checkIfDone();
      }

      function checkIfDone(src) {
        if (totalLoaded === totalImages) {
          setLoaded(true);
        }
      }

      function preloadImage(src) {
        const img = new Image();
        const promise = new Promise((resolve, reject) => {
          img.onload = function () {
            resolve(img);
            totalLoaded += 1;
            calcProgress();
          };
          img.onerror = img.onabort = function () {
            reject(src);
          };
          img.src = src;
        });

        return {
          promise,
          img,
        };
      }

      try {
        useGLTF.preload(models.abstract);
        useGLTF.preload(models.shiba);
        useGLTF.preload(models.quill);
        useGLTF.preload(models.guitar);
        useGLTF.preload(models.disco);

        const AbstractTexture = new TextureLoader();
        AbstractTexture.load(
          "./images/environment2-blur3.jpg",
          function (texture) {
            texts.abstract = texture;
            totalLoaded += 1;
            calcProgress();
          }
        );

        const DiscoTexture = new TextureLoader();
        DiscoTexture.load("./images/environment-blur.png", function (texture) {
          texts.disco = texture;
          totalLoaded += 1;
          calcProgress();
        });

        const Abstract = new GLTFLoader();
        Abstract.load(models.abstract, () => {
          totalLoaded += 1;
          calcProgress();
        });

        const Shiba2 = new GLTFLoader();
        Shiba2.load(models.shiba, () => {
          totalLoaded += 1;
          calcProgress();
        });

        const Quill = new GLTFLoader();
        Quill.load(models.quill, () => {
          totalLoaded += 1;
          calcProgress();
        });

        const Guitar = new GLTFLoader();
        Guitar.load(models.guitar, () => {
          totalLoaded += 1;
          calcProgress();
        });

        const DiscoBall = new GLTFLoader();
        DiscoBall.load(models.disco, () => {
          totalLoaded += 1;
          calcProgress();
        });

        import("../../images/manifesto_gradient.webp").then((resp) => {
          imageObject.manifesto_gradient = resp.default;
          totalLoaded += 1;
          calcProgress();
        });
        import("../../images/manifesto_01.webp").then((resp) => {
          imageObject.manifesto_01 = resp.default;
          totalLoaded += 1;
          calcProgress();
        });
        import("../../images/manifesto_02.webp").then((resp) => {
          imageObject.manifesto_02 = resp.default;
          totalLoaded += 1;
          calcProgress();
        });
        import("../../images/avocado_toast.webp").then((resp) => {
          imageObject.avocado_toast = resp.default;
          totalLoaded += 1;
          calcProgress();
        });
        import("../../images/avocado-1.webp").then((resp) => {
          imageObject.avocado_1 = resp.default;
          totalLoaded += 1;
          calcProgress();
        });
        import("../../images/avocado-2.webp").then((resp) => {
          imageObject.avocado_2 = resp.default;
          totalLoaded += 1;
          calcProgress();
        });
        import("../../images/avocado-3.webp").then((resp) => {
          imageObject.avocado_3 = resp.default;
          totalLoaded += 1;
          calcProgress();
        });
        import("../../images/avocado-4.webp").then((resp) => {
          imageObject.avocado_4 = resp.default;
          totalLoaded += 1;
          calcProgress();
        });
        import("../../images/galileo.webp").then((resp) => {
          imageObject.galileo = resp.default;
          totalLoaded += 1;
          calcProgress();
        });
        import("../../images/robot_beach.webp").then((resp) => {
          imageObject.robot_beach = resp.default;
          totalLoaded += 1;
          calcProgress();
        });
        import("../../images/cute_robot1.webp").then((resp) => {
          imageObject.cute_robot1 = resp.default;
          totalLoaded += 1;
          calcProgress();
        });
        import("../../images/tin_robot3.webp").then((resp) => {
          imageObject.tin_robot3 = resp.default;
          totalLoaded += 1;
          calcProgress();
        });
        import("../../images/orange_robot1.webp").then((resp) => {
          imageObject.orange_robot1 = resp.default;
          totalLoaded += 1;
          calcProgress();
        });
        import("../../images/tom_gif.gif").then((resp) => {
          imageObject.tom_gif = resp.default;
          totalLoaded += 1;
          calcProgress();
        });
        import("../../images/avocado_mobile.webp").then((resp) => {
          imageObject.avocado_mobile = resp.default;
          totalLoaded += 1;
          calcProgress();
        });

        setManifestoImages(imageObject);
        setTextures(texts);

        preloadImage("./images/environment2-blur3.jpg");
        preloadImage("./images/environment-blur.png");
      } catch (error) {
        console.error("Error importing images:", error);
      }
    };
    importImages();
  }, []);

  return {
    MANIFESTO_IMAGES,
    progress,
    loaded,
    textures,
  };
};
