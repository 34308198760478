import React from "react";

import ParagraphBlock from "../components/ParagraphBlock";
import Footer from "../components/Footer/FooterV2";
import { ManifestoBlack } from "../components/Manifesto/ManifestoBlack";
import { RobotsModule } from "../components/Robots/RobotsModule";

import Hero from "../components/Hero/Hero";
import STRINGS from "../components/constants/strings";
import Nav from "../components/Nav";
import MainMenu from "../components/MainMenu/ModuleMainMenu";

const DefaultLanding = ({
	player,
	initial,
	options,
	manifestoImages,
	handlePlayerChange,
	changeRoute,
	fromBottom,
	resetFromBottom,
	textures,
	progress,
	loaded,
}) => {
	return (
		<div className="main-wrapper">
			<MainMenu isArticle={false} />
			<Nav isLandingPage />
			<Hero
				handlePlayerChange={handlePlayerChange}
				player={player}
				initial={initial}
				changeRoute={changeRoute}
				fromBottom={fromBottom}
				resetFromBottom={resetFromBottom}
				textures={textures}
				progress={progress}
				loaded={loaded}
			/>
			<ParagraphBlock
				backgroundImage={options.pBlock.backgroundImage}
				contentString={STRINGS.P_BLOCK_DEFAULT}
				subHeaderString={STRINGS.P_BLOCK_DEFAULT_SUB}
				scrollOffset={["start center", "start start"]}
				scrollYArray={[0, 1]}
				opacityArray={[0, 0.7]}
				// opacityOverride={0.6}
				scrollInfo={[0.14, 0.16, 0.18, 0.2]}
				sectionHeight={150}
			/>
			<ParagraphBlock
				//backgroundImage={options.pBlock.backgroundImage}
				glitchImage={options.pBlock.glitchImage}
				contentString={options.pBlock.contentString}
				subHeaderString={options.pBlock.subHeaderString}
				scrollOffset={["start center", "start start"]}
				scrollYArray={[0, 0.5, 0.5, 1]}
				opacityArray={[0, 0.7, 0.7, 1]}
				opacityOverride={0}
				typed
				handlePlayerChange={handlePlayerChange}
				hasMenu
				selectedPlayer={player}
				scrollInfo={[0.3, 0.33, 0.38, 0.4]}
				sectionHeight={350}
			/>
			<ManifestoBlack
				handlePlayerChange={handlePlayerChange}
				selectedPlayer={player}
				images={manifestoImages}
			/>
			<RobotsModule />
			<Footer />
		</div>
	);
};

export default DefaultLanding;
