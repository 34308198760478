import { useEffect } from "react";
import "./Loader.css";
import { motion, cubicBezier } from "framer-motion";

const Loader = ({ progress, loaded }) => {
  const height = progress > 0 ? `${progress}vh` : 0;

  useEffect(() => {
    const mainNav = document.querySelector(".mainNav");
    const body = document.querySelector("body");
    if (!loaded) {
      setTimeout(() => {
        body.style.overflowY = "hidden";
        mainNav.style.top = "-200px";
      }, 0);
    } else {
      setTimeout(() => {
        body.style.overflow = "";
        mainNav.style.top = "";
      }, 2000);
    }
  }, [loaded]);

  return (
    <div className="loader-container">
      <motion.div
        className="loader-bar"
        animate={{ height, opacity: [1, 1, loaded ? 0 : 1] }}
        transition={{
          ease: cubicBezier(0.35, 0.17, 0.3, 0.86),
          duration: 1,
          delay: 0,
          times: [0, 1, 1],
        }}
      ></motion.div>
      {loaded ? (
        <>
          <motion.div
            className="loader-courtain_left"
            animate={{ x: "-100%" }}
            transition={{
              ease: cubicBezier(0.35, 0.17, 0.3, 0.86),
              duration: 1,
              delay: 1,
            }}
          ></motion.div>
          <motion.div
            className="loader-courtain_right"
            animate={{ x: "100%" }}
            transition={{
              ease: cubicBezier(0.35, 0.17, 0.3, 0.86),
              duration: 1,
              delay: 1,
            }}
          ></motion.div>
        </>
      ) : (
        <>
          <div className="loader-courtain_left"></div>
          <div className="loader-courtain_right"></div>
        </>
      )}
    </div>
  );
};
export { Loader };
