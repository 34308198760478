import React from "react";
import MainMenu from "../components/MainMenu/ModuleMainMenu";
import Footer from "../components/Footer/FooterV2";
import "./error404.css";

const Error404 = () => {
  return (
    <div className="main-wrapper">
      <MainMenu />
      <div className="errorWrapper">
        <div>
          <img
            src={process.env.PUBLIC_URL + "/images/404_2.png"}
            alt=""
            className="error_1"
          />
        </div>
        <div>
          <h1>404 PAGE</h1>
          <h1>NOT FOUND</h1>
          <h2>YOU AREN'T LOST JUST BECAUSE YOU DON'T KNOW WHERE YOU ARE...</h2>
        </div>
        <div>
          <img
            src={process.env.PUBLIC_URL + "/images/404_1.png"}
            alt=""
            className="error_2"
          />
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Error404;
