import React, { useState } from "react";
import "./Newsletter.css";
import { useFormik } from "formik";
import axios from "axios";

const Copyright = () => {
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [hasError, setHasError] = useState(false);

  const handleBackToForm = () => {
    setHasError(false);
    setIsSubmitted(false);
  };

  const handleSubmit = async (postData) => {
    axios({
      method: "post",
      url: "https://go.lippincott.com/l/974703/2024-01-25/cxt38",
      data: `email=${postData.email}`,
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    })
      .then(function (response) {
        //handle success
        console.log(response);
        setIsSubmitted(true);
      })
      .catch(function (response) {
        //handle error
        console.log(response);
        setHasError(true);
        setIsSubmitted(true);
      });
  };

  if (isSubmitted) {
    return (
      <div className="newsletter">
        <div className="footerWrapper">
          <div className="newsletterContentWrapper dFlex spaceBetween alignItemsStart">
            <div className="thanks">
              {hasError ? (
                <>
                  <h4>Submitted.</h4>
                  <h4>Hope to talk soon.</h4>
                  <h3 className="mt-60">Thank you.</h3>
                </>
              ) : (
                <>
                  <h4>Submitted.</h4>
                  <h4>Hope to talk soon.</h4>
                  <h3 className="mt-60">Thank you.</h3>
                </>
              )}

              <button
                className="gradientButton mt-60"
                onClick={handleBackToForm}
              >
                Back to form
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="newsletter">
      <div className="footerWrapper">
        <div className="newsletterContentWrapper dFlex spaceBetween alignItemsStart">
          <div className="newsletterInput">
            <h3 className="victor50px">
              Stay updated. Stay connected. Join our newsletter
            </h3>
            <form
              className="footerForm joinUsForm dFlex spaceBetween alignItemsCenter"
              onSubmit={formik.handleSubmit}
            >
              <input
                type="email"
                name="email"
                id="email"
                onChange={formik.handleChange}
                value={formik.values.password}
                placeholder="Email address"
                required
              />
              <button type="submit">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="29"
                  height="21"
                  viewBox="0 0 29 21"
                  fill="none"
                >
                  <line
                    y1="10.7372"
                    x2="27.4133"
                    y2="10.7372"
                    stroke="#ADADAD"
                    strokeWidth="0.963147"
                  />
                  <path
                    d="M18.1719 1L27.9988 10.8269L18.1719 20.6538"
                    stroke="#ADADAD"
                    strokeWidth="0.963147"
                  />
                </svg>
              </button>
            </form>
          </div>
          <div className="siteLinks dFlex">
            <div className="siteLinksContainer">
              <ul>
                <li>
                  <a
                    href="https://lippincott.com/careers/"
                    target="coolTab"
                    rel="noopener noreferrer"
                  >
                    Careers
                  </a>
                </li>
                <li>
                  <a
                    href="https://lippincott.com/work/"
                    target="coolTab"
                    rel="noopener noreferrer"
                  >
                    Our Work
                  </a>
                </li>
                <li>
                  <a
                    href="https://lippincott.com/get-in-touch/"
                    target="coolTab"
                    rel="noopener noreferrer"
                  >
                    Locations
                  </a>
                </li>
              </ul>
            </div>
            <div className="siteLinksContainer">
              <ul>
                <li>
                  <a
                    href="https://lippincott.com/about-us/"
                    target="coolTab"
                    rel="noopener noreferrer"
                  >
                    About Us
                  </a>
                </li>
                <li>
                  <a
                    href="https://lippincott.com/solutions/"
                    target="coolTab"
                    rel="noopener noreferrer"
                  >
                    Solutions
                  </a>
                </li>
                <li dangerouslySetInnerHTML={{
                   __html: `
                    <a href="#" onclick="Osano.cm.showDrawer('osano-cm-dom-info-dialog-open')">Manage Cookies</a>
                    `
                }} />
               
              </ul>
            </div>
            <div className="socialLinks">
              <ul className="dFlex">
                <li>
                  <a
                    href="https://www.instagram.com/lippincottbrand/"
                    target="coolTab"
                    rel="noopener noreferrer"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22"
                      height="20"
                      viewBox="0 0 22 20"
                      fill="none"
                    >
                      <path
                        d="M10.5941 4.87225C7.62065 4.87225 5.22222 7.16166 5.22222 10C5.22222 12.8383 7.62065 15.1277 10.5941 15.1277C13.5676 15.1277 15.9661 12.8383 15.9661 10C15.9661 7.16166 13.5676 4.87225 10.5941 4.87225ZM10.5941 13.3337C8.6726 13.3337 7.10169 11.8387 7.10169 10C7.10169 8.16133 8.66792 6.66629 10.5941 6.66629C12.5204 6.66629 14.0866 8.16133 14.0866 10C14.0866 11.8387 12.5157 13.3337 10.5941 13.3337V13.3337ZM17.4388 4.6625C17.4388 5.32746 16.8778 5.85853 16.1858 5.85853C15.4892 5.85853 14.9328 5.32299 14.9328 4.6625C14.9328 4.00201 15.4939 3.46647 16.1858 3.46647C16.8778 3.46647 17.4388 4.00201 17.4388 4.6625ZM20.9967 5.87638C20.9172 4.27424 20.5338 2.85507 19.3042 1.68582C18.0793 0.516568 16.5926 0.150619 14.9141 0.070289C13.1843 -0.0234297 7.99935 -0.0234297 6.26949 0.070289C4.59573 0.146156 3.10898 0.512105 1.87938 1.68136C0.649773 2.85061 0.271073 4.26978 0.186917 5.87192C0.0887359 7.52315 0.0887359 12.4724 0.186917 14.1236C0.266398 15.7258 0.649773 17.1449 1.87938 18.3142C3.10898 19.4834 4.59106 19.8494 6.26949 19.9297C7.99935 20.0234 13.1843 20.0234 14.9141 19.9297C16.5926 19.8538 18.0793 19.4879 19.3042 18.3142C20.5292 17.1449 20.9125 15.7258 20.9967 14.1236C21.0949 12.4724 21.0949 7.52761 20.9967 5.87638V5.87638ZM18.7619 15.8953C18.3972 16.7701 17.6913 17.4439 16.7702 17.7965C15.391 18.3186 12.1183 18.1981 10.5941 18.1981C9.07 18.1981 5.79261 18.3142 4.41807 17.7965C3.50171 17.4484 2.79574 16.7745 2.42639 15.8953C1.87938 14.5788 2.00561 11.4549 2.00561 10C2.00561 8.54513 1.88405 5.41671 2.42639 4.10465C2.79106 3.22995 3.49703 2.55606 4.41807 2.2035C5.79728 1.68136 9.07 1.80185 10.5941 1.80185C12.1183 1.80185 15.3957 1.68582 16.7702 2.2035C17.6866 2.5516 18.3926 3.22548 18.7619 4.10465C19.3089 5.42118 19.1827 8.54513 19.1827 10C19.1827 11.4549 19.3089 14.5833 18.7619 15.8953Z"
                        fill="white"
                      />
                    </svg>
                  </a>
                </li>
                <li>
                  <a
                    href="https://twitter.com/lippincottbrand"
                    target="coolTab"
                    rel="noopener noreferrer"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="20"
                      viewBox="0 0 24 20"
                      fill="none"
                    >
                      <path
                        d="M18.3801 0H21.7743L14.3609 8.47115L23.082 20H16.2551L10.9041 13.0096L4.78876 20H1.38972L9.31761 10.9375L0.957031 0H7.95703L12.7888 6.38942L18.3801 0ZM17.1878 17.9712H19.0676L6.93299 1.92308H4.91376L17.1878 17.9712Z"
                        fill="white"
                      />
                    </svg>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/lippincott/"
                    target="coolTab"
                    rel="noopener noreferrer"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="21"
                      height="20"
                      viewBox="0 0 21 20"
                      fill="none"
                    >
                      <path
                        d="M18.6535 0C19.4124 0 20.082 0.669643 20.082 1.47321V18.5714C20.082 19.375 19.4124 20 18.6535 20H1.46596C0.707031 20 0.0820312 19.375 0.0820312 18.5714V1.47321C0.0820312 0.669643 0.707031 0 1.46596 0H18.6535ZM6.10882 17.1429V7.63393H3.16239V17.1429H6.10882ZM4.6356 6.29464C5.5731 6.29464 6.33203 5.53571 6.33203 4.59821C6.33203 3.66071 5.5731 2.85714 4.6356 2.85714C3.65346 2.85714 2.89453 3.66071 2.89453 4.59821C2.89453 5.53571 3.65346 6.29464 4.6356 6.29464ZM17.2249 17.1429V11.9196C17.2249 9.375 16.6445 7.36607 13.6535 7.36607C12.2249 7.36607 11.2427 8.16964 10.841 8.92857H10.7963V7.63393H7.98382V17.1429H10.9302V12.4554C10.9302 11.2054 11.1535 10 12.716 10C14.2338 10 14.2338 11.4286 14.2338 12.5V17.1429H17.2249Z"
                        fill="white"
                      />
                    </svg>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Copyright;
