const STRINGS = {
    P_BLOCK_DEFAULT: 'These pages are dedicated to an active and open exploration of generative AI through its most interesting use cases in our field, creative consulting.',
    P_BLOCK_DEFAULT_SUB: 'As an industry we know we must prepare for a future of AI-enhanced services. We\'re excited you\'re here to learn, and PLAY, too.',
    P_BLOCK: 'To say the least, Artificial Intelligence is one of the most transformative things to ever happen to modern society—rivaling every digital innovation that’s come before it. And for good reason. It has the potential to disrupt, well, everything.',
    P_BLOCK_SUB: 'In order to learn, we must PLAY.  So, let’s get going:',
    P_BLOCK_2: 'To the moon! AI is like the blockchain of innovation, making waves  and barking at the heels of every digital breakthrough that\'s gone before. And there\'s a good reason for all the tail-wagging excitement. AI has the potential to shake up the game. We\'ve got to HODL onto AI like it\'s our favorite squeaky toy. ',
    P_BLOCK_2_SUB: 'Such AI, much creativity, very innovation! 🚀🐕🎾',
    P_BLOCK_3: 'High key, Artificial Intelligence is like the ultimate glow-up for modern society—straight-up rivaling every digital innovation that\'s gone down. And you already know it\'s for good reason. AI\'s got the potential to shake up the whole game, iykyk. To level up our knowledge, we gotta swipe right on this AI journey and make it lit.',
    P_BLOCK_3_SUB: 'So, let\'s get this party started, fam! 🚀💯💥',
    P_BLOCK_4: 'Hark, forsooth! AI, a transformation most profound, rivaling e\'en the inventions preceding, finds its place within the modern society\'s tapestry. And for reasons of merit most noble, it bears the potential to disrupt the very fundament of our world. To acquire knowledge, we must engage in this PLAY with the utmost earnestness.',
    P_BLOCK_4_SUB: 'Hence, let us commence this expedition forthwith:',
    P_BLOCK_5: 'Welcome to Play, where we embark on an agile and collaborative AI exploration, delving into the cutting-edge use cases it presents within our specialized domain of creative consulting. We eagerly look forward to your presence and contributions.',
    P_BLOCK_6: 'Like, you know, Artificial Intelligence, it\'s, like, one of the most transformational things to ever hit modern society, man. AI has the potential to thrash and tear the whole stage apart. And, you know, there\'s a damn good reason for that. It\'s got the potential to mess up, like, everything.',
    P_BLOCK_6_SUB: 'So, let\'s dive into this epic AI mosh pit and rock out! 🎸🤘🔥',
    TEXT_IMG_BLOCK_HEADER_1: 'We need to talk about AI',
    TEXT_IMG_BLOCK_HEADER_2: 'AI, we gotta bark it out!',
    TEXT_IMG_BLOCK_HEADER_3: 'How we\'re manifesting AI',
    TEXT_IMG_BLOCK_HEADER_4: 'Pray, we must converse of AI',
    TEXT_IMG_BLOCK_HEADER_5: 'Let\'s circle back on AI',
    TEXT_IMG_BLOCK_HEADER_6: 'Calling all AI headbangers',
    TEXT_IMG_BLOCK_CONTENT_1: 'To say the least, Artificial Intelligence has become one of the most transformative things to ever happen to modern society—rivaling everything that’s come before it. And for good reason. It has the potential to disrupt, well, everything.',
    TEXT_IMG_BLOCK_CONTENT_2: 'Bark bark! Artificial Intelligence is like a magic bone that\'s changin\' everything in our world! It\'s takin\' on all the past stuff, and for good reason. It\'s got the power to shake up, well, everything!',
    TEXT_IMG_BLOCK_CONTENT_3: 'OMG, no lie, Artificial Intelligence is like the biggest game-changer ever for our society! It\'s totally on another level, like, outdoing everything that came before. And the best part? It\'s got the power to shake things up, like, EVERYTHING! 🤯💥',
    TEXT_IMG_BLOCK_CONTENT_4: 'To utter succinctly, Artificial Intelligence hath turned into one of society\'s most transformative marvels, rivaling all that came ere it. And for sound reason. It holds the potency to disrupt, well, everything.',
    TEXT_IMG_BLOCK_CONTENT_5: 'Indubitably, Artificial Intelligence marks a paramount shift, transforming contemporary society in ways to rival all prior developments. Reasonably so. Its potential to disrupt is far-reaching, affecting every facet of existence.',
    TEXT_IMG_BLOCK_CONTENT_6: 'Behold, the rise of Artificial Intelligence, an unparalleled force of metal fury, conquering all realms of modern society. And with righteous cause. Its potential to shatter and rend is boundless, laying waste to all.',
    ARTICLE_HEADER_1: 'We need to talk about AI',
    ARTICLE_HEADER_2: 'Applying the Power of AI to Naming',
    ARTICLE_HEADER_3: 'Naming: The Next Generation ',
    ARTICLE_HEADER_4: 'What does AI mean for how we do customer research? ',
    ARTICLE_SUBHEADER_1: 'BY TOM AJELLO',
    ARTICLE_SUBHEADER_2: 'BY VALERIE GARRAL',
    ARTICLE_SUBHEADER_3: 'BY HAILEY SCHERER',
    ARTICLE_SUBHEADER_4: 'BY AUTHOR NAME',
    ARTICLE_BLOCK_HEADER: 'Keep exploring',
    MEET_TEAM_BLOCK_HEADER_1: 'Meet some of the team',
    MEET_TEAM_BLOCK_BUTTON_1: 'SEE ALL PEOPLE',
    FOOTER_MODULE_TOP: {
        HEADER: 'Reach out to us to talk about AI',
        SUBHEADER: 'For inquiries about AI opportunities in branding and experience',
    }
};

export default STRINGS;