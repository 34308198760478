import React from 'react';
import './MainCTA.css';

const MainCTA = () => {
  return (
      <div className='mainCTA'>
        <div className='footerWrapper'>
          <div className='dFlex flexColumn alignItemsCenter'>
            <h2>Lippincott helps world class brands break through</h2>
            <span>Across industries and the globe our clients rely on Lippincott to build what's next</span>
            <a href='https://lippincott.com/' className='gradientButton' target='_blank' rel="noreferrer">See How</a>
          </div>
        </div>
      </div>
  );
};

export default MainCTA;