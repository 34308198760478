import React, { useState, useEffect } from "react";
import styled from "styled-components";
import "./GetInTouch.css";
import { useScrollDirection } from "react-use-scroll-direction";
import axios from "axios";
import { useFormik } from "formik";

const GetInTouchWrapper = styled.div.attrs({
  className: "getInTouch",
})`
  background-image: url("../../images/footer/get-in-touch.jpg");
`;

const GetInTouch = () => {
  const [isBodyOpen, setIsBodyOpen] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [hasError, setHasError] = useState(false);

  const { isScrollingUp } = useScrollDirection();

  const formik = useFormik({
    initialValues: {
      lippincott_office: "New_York",
      get_in_touch: "Partnering_with_us",
      full_name: "",
      email: "",
      company: "",
      project_details: "",
    },
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const handleBackToForm = () => {
    setHasError(false);
    setIsSubmitted(false);
  };

  const handleSubmit = async (postData) => {
    const queryString = Object.entries(postData)
      .map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
      .join("&");
    console.warn(queryString);
    axios({
      method: "post",
      url: "https://go.lippincott.com/l/974703/2024-01-25/cxsy5",
      data: queryString,
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    })
      .then(function (response) {
        //handle success
        console.log(response);
        setIsSubmitted(true);
      })
      .catch(function (response) {
        //handle error
        console.log(response);
        setHasError(true);
        setIsSubmitted(true);
      });
  };

  const openFormClick = () => {
    setIsBodyOpen(true);
    document
      .getElementById("getInTouchWrap")
      .scrollIntoView({ behavior: "smooth" });
  };

  const closeFormClick = () => {
    setIsBodyOpen(false);
  };

  useEffect(() => {
    const mainNavElement = document.querySelector(".mainNav");
    if (isBodyOpen) {
      document.body.classList.add("openGetInTouch");
      document
        .getElementById("getInTouchWrap")
        .scrollIntoView({ behavior: "smooth" });
      mainNavElement.style.transform = "translateY(-100%)";
    } else {
      document.body.classList.remove("openGetInTouch");
      mainNavElement.style.transform = "translateY(0)";
    }
  }, [isBodyOpen]);

  useEffect(() => {
    if (isScrollingUp) {
      const handleScroll = (event) => {
        setIsBodyOpen(false);
      };
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, [isScrollingUp]);

  return (
    <div>
      <GetInTouchWrapper>
        <div className="footerWrapper">
          <div
            id="getInTouchWrap"
            className="getInTouchButton dFlex"
            onClick={openFormClick}
          >
            <div>
              <h3>Get in touch with us</h3>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M38.3944 19.9999C38.3944 30.0952 30.2105 38.2791 20.1151 38.2791C10.0197 38.2791 1.83583 30.0952 1.83583 19.9999C1.83583 9.9045 10.0197 1.72059 20.1151 1.72059C30.2105 1.72059 38.3944 9.9045 38.3944 19.9999ZM39.4099 19.9999C39.4099 30.6561 30.7713 39.2946 20.1151 39.2946C9.45888 39.2946 0.820312 30.6561 0.820312 19.9999C0.820312 9.34365 9.45888 0.705078 20.1151 0.705078C30.7713 0.705078 39.4099 9.34365 39.4099 19.9999ZM9.95995 16.9533L20.1151 28.124L30.2702 16.9533L9.95995 16.9533Z"
                  fill="#BA9CFF"
                />
              </svg>
            </div>
          </div>
        </div>
      </GetInTouchWrapper>
      <div className="touchForm">
        <div className="closeForm" onClick={closeFormClick}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="36"
            height="37"
            viewBox="0 0 36 37"
            fill="none"
          >
            <line
              x1="1.29289"
              y1="35.0766"
              x2="34.9512"
              y2="1.4183"
              stroke="white"
              strokeWidth="2"
            />
            <line
              x1="35.2929"
              y1="35.6407"
              x2="1.63461"
              y2="1.98242"
              stroke="white"
              strokeWidth="2"
            />
          </svg>
        </div>
        <div className="footerWrapper">
          {isSubmitted ? (
            <div className="dFlex spaceBetween alignItemsStart">
              <div className="thanks">
                {hasError ? (
                  <>
                ````<h4 className="mt-100">Submitted.</h4>
                    <h4>Hope to talk soon.</h4>
                    <h3 className="mt-60">Thank you.</h3>
                  </>
                ) : (
                  <>
                    <h4 className="mt-100">Submitted.</h4>
                    <h4>Hope to talk soon.</h4>
                    <h3 className="mt-60">Thank you.</h3>
                  </>
                )}

                <button
                  className="gradientButton mt-60"
                  onClick={handleBackToForm}
                >
                  Back to form
                </button>
              </div>
            </div>
          ) : (
            <form onSubmit={formik.handleSubmit}>
              <div className="dFlex spaceBetween alignItemsStart">
                <div className="talkAI">
                  <h3>
                    Talk AI with our Experience Innovation & Engineering team
                  </h3>
                  <div className="dropdownTouch dFlex flexColumn">
                    <label className="dFlex">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="13"
                        viewBox="0 0 10 13"
                        fill="none"
                      >
                        <path
                          d="M4.87578 0.959961C2.55378 0.959961 0.675781 2.83796 0.675781 5.15996C0.675781 8.30996 4.87578 12.96 4.87578 12.96C4.87578 12.96 9.07578 8.30996 9.07578 5.15996C9.07578 2.83796 7.19778 0.959961 4.87578 0.959961ZM4.87578 6.65996C4.04778 6.65996 3.37578 5.98796 3.37578 5.15996C3.37578 4.33196 4.04778 3.65996 4.87578 3.65996C5.70378 3.65996 6.37578 4.33196 6.37578 5.15996C6.37578 5.98796 5.70378 6.65996 4.87578 6.65996Z"
                          fill="white"
                        />
                      </svg>
                      Lippincott Office:
                    </label>
                    <select
                      name="lippincott_office"
                      id="lippincott_office"
                      onChange={formik.handleChange}
                      value={formik.values.password}
                    >
                      <option value="New_York">New York</option>
                      <option value="Boston">Boston</option>
                      <option value="Dubai">Dubai</option>
                      <option value="Hong_Kong">Hong Kong</option>
                      <option value="London">London</option>
                      <option value="San_Francisco">San Francisco</option>
                      <option value="Seoul">Seoul</option>
                      <option value="Shanghai">Shanghai</option>
                      <option value="Singapore">Singapore</option>
                      <option value="Sydney">Sydney</option>
                    </select>
                  </div>
                  <div className="dropdownTouch dFlex flexColumn">
                    <label>Get in touch with us about</label>
                    <select
                      name="get_in_touch"
                      id="get_in_touch"
                      onChange={formik.handleChange}
                      value={formik.values.password}
                    >
                      <option value="Partnering_with_us">
                        Partnering with us
                      </option>
                      <option value="Media_Inquiries">Media Inquiries</option>
                      <option value="General_Questions">
                        General Questions
                      </option>
                    </select>
                  </div>
                </div>
                <div className="talkAIform footerForm contactForm dFlex flexColumn">
                  <div>
                    <label htmlFor="full_name">Full Name *</label>
                    <input
                      type="text"
                      id="full_name"
                      onChange={formik.handleChange}
                      value={formik.values.password}
                      name="full_name"
                      placeholder="Your full name"
                      required
                    />
                  </div>
                  <div>
                    <label htmlFor="email">Email address *</label>
                    <input
                      type="email"
                      id="email"
                      onChange={formik.handleChange}
                      value={formik.values.password}
                      name="email"
                      placeholder="Your Email"
                      required
                    />
                  </div>
                  <div>
                    <label htmlFor="company">Company *</label>
                    <input
                      type="text"
                      id="company"
                      onChange={formik.handleChange}
                      value={formik.values.password}
                      name="company"
                      placeholder="Your Company name"
                      required
                    />
                  </div>
                  <div>
                    <label htmlFor="project_details">Message</label>
                    <textarea
                      id="project_details"
                      onChange={formik.handleChange}
                      value={formik.values.password}
                      name="project_details"
                      rows="4"
                      placeholder="Leave us a message"
                    ></textarea>
                  </div>
                  <button className="gradientButton" type="submit">
                    Send Message
                  </button>
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default GetInTouch;
