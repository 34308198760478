import { Canvas } from "@react-three/fiber";
import {
  EquirectangularReflectionMapping,
  MeshStandardMaterial,
  PMREMGenerator,
  SRGBColorSpace,
  WebGLRenderer,
} from "three";
import { OrbitControls, useGLTF } from "@react-three/drei";
import { Suspense, useEffect, useState } from "react";
import { models } from "../constants/models";

const AbstractCanvas = ({ active, textures }) => {
  const [isReady, setIsReady] = useState(false);
  const model = useGLTF(models.abstract);

  useEffect(() => {
    const text = textures.abstract;

    if (!text) {
      return;
    }

    text.mapping = EquirectangularReflectionMapping;
    text.colorSpace = SRGBColorSpace;

    const renderer = new WebGLRenderer();
    const pmremGenerator = new PMREMGenerator(renderer);
    pmremGenerator.compileEquirectangularShader();
    const newMat = new MeshStandardMaterial({
      color: 0xffffff,
      roughness: 0.11,
      metalness: 0.85,
    });

    model.scene.traverse((child) => {
      if (child.isMesh) {
        child.material = newMat;
        child.material.envMap = text;
        child.material.envMapIntensity = 1;
        child.material.needsUpdate = true;
      }
    });

    setIsReady(true);
  }, [model, textures.abstract]);

  if (!isReady) return <></>;

  return (
    <div
      className="canvas-wrapper"
      style={{
        width: "100%",
        position: "relative",
        display: !active ? "none" : "",
      }}
    >
      <Canvas
        frameloop="demand"
        camera={{ position: [0, 1, -8], fov: 35 }}
        onCreated={({ camera, scene }) => {
          scene.add(camera);
        }}
      >
        <ambientLight intensity={2} />
        <Suspense fallback="Loading...">
          <OrbitControls
            autoRotate
            enableZoom={false}
            target={[0, 0, 0]}
            enabled={active}
          />
          <primitive
            object={model.scene}
            scale={1.1}
            position={[0, -0.2, 0]}
            rotation={[0, Math.PI, 0.4]}
          />
        </Suspense>
      </Canvas>
    </div>
  );
};

export default AbstractCanvas;
