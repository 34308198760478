import "./App.css";
import React, { useState } from "react";
import STRINGS from "./components/constants/strings";
import DefaultLanding from "./pages/DefaultLanding";
import NAMING_STRINGS from "./components/constants/textContent";
import { NamingSection } from "./pages/NamingSection";
import { Route, Routes } from "react-router-dom";
import { NamingExperimentSection } from "./pages/NamingExperimentSection";
import PowerOfNaming from "./pages/PowerOfNaming";
import NamingNG from "./pages/NamingNextGeneration";
import ListeningLive from "./pages/ListeningLive";
import AIHumans from "./pages/AIUnderstandingHumans";
import ListeningTC from "./pages/ListeningToConversing";
import ReplicatingHumanSentiment from "./pages/ReplicatingHumanSentiment";
import Lilah from "./pages/Lilah";
import AIandCulture from "./pages/AIandCulture";


// Google Analytics ------------------
import ReactGA from "react-ga4";
import Error404 from "./pages/Error404";
import { useImageLoader } from "./components/constants/manifestoImgContent";

function App() {
	React.useEffect(() => {
		ReactGA.initialize("G-FM932CS8SD");
		ReactGA.send("pageview");
	}, []);
	const players = [
		{
			pBlock: {
				backgroundImage: "images/p-block-1.jpg",
				glitchImage: "images/glitch-1.jpg",
				contentString: STRINGS.P_BLOCK,
				subHeaderString: STRINGS.P_BLOCK_SUB,
			},
			textImageBlock: {
				gradient: "images/gradient-1.webp",
				image: "images/glitch-1.jpg",
				header: STRINGS.TEXT_IMG_BLOCK_HEADER_1,
				content: STRINGS.TEXT_IMG_BLOCK_CONTENT_1,
			},
		},
		{
			pBlock: {
				backgroundImage: "images/p-block-2.jpg",
				glitchImage: "images/glitch-2.jpg",
				contentString: STRINGS.P_BLOCK_2,
				subHeaderString: STRINGS.P_BLOCK_2_SUB,
			},
			textImageBlock: {
				gradient: "images/gradient-2.webp",
				image: "images/glitch-1.jpg",
				header: STRINGS.TEXT_IMG_BLOCK_HEADER_2,
				content: STRINGS.TEXT_IMG_BLOCK_CONTENT_2,
			},
		},
		{
			pBlock: {
				backgroundImage: "images/p-block-4.jpg",
				glitchImage: "images/glitch-3.jpg",
				contentString: STRINGS.P_BLOCK_4,
				subHeaderString: STRINGS.P_BLOCK_4_SUB,
			},
			textImageBlock: {
				gradient: "images/gradient-4.webp",
				image: "images/text-img-block-4.webp",
				header: STRINGS.TEXT_IMG_BLOCK_HEADER_4,
				content: STRINGS.TEXT_IMG_BLOCK_CONTENT_4,
			},
		},
		{
			pBlock: {
				backgroundImage: "images/p-block-6.jpg",
				glitchImage: "images/glitch-4.jpg",
				contentString: STRINGS.P_BLOCK_6,
				subHeaderString: STRINGS.P_BLOCK_6_SUB,
			},
			textImageBlock: {
				gradient: "images/gradient-6.jpg",
				image: "images/glitch-1.jpg",
				header: STRINGS.TEXT_IMG_BLOCK_HEADER_6,
				content: STRINGS.TEXT_IMG_BLOCK_CONTENT_6,
			},
		},
		{
			pBlock: {
				backgroundImage: "images/p-block-3.jpg",
				glitchImage: "images/glitch-5.jpg",
				contentString: STRINGS.P_BLOCK_3,
				subHeaderString: STRINGS.P_BLOCK_3_SUB,
			},
			textImageBlock: {
				gradient: "images/gradient-3.webp",
				image: "images/text-img-block-3.webp",
				header: STRINGS.TEXT_IMG_BLOCK_HEADER_3,
				content: STRINGS.TEXT_IMG_BLOCK_CONTENT_3,
			},
		},
	];

	const [archetype, setArchetype] = useState(0);
	const [initialArch, setInitialArch] = useState(true);
	const [fromBottom, setFromBottom] = useState(false);

	const { MANIFESTO_IMAGES, textures, progress, loaded } = useImageLoader();

	const handlePlayerChange = (newPlayer, fromBottom) => {
		console.log("handle player change called");
		setInitialArch(false);
		setFromBottom(fromBottom);
		if (archetype !== newPlayer) {
			setArchetype(newPlayer);
		}
	};
	const resetFromBottom = (fromB) => {
		setFromBottom(false);
	};

	const LandingPage = (
		<DefaultLanding
			options={players[archetype]}
			handlePlayerChange={handlePlayerChange}
			player={archetype}
			initial={initialArch}
			fromBottom={fromBottom}
			resetFromBottom={resetFromBottom}
			manifestoImages={MANIFESTO_IMAGES}
			textures={textures}
			progress={progress}
			loaded={loaded}
		/>
	);

	return (
		<div className="App">
			<Routes>
				<Route path="*" element={<Error404 />} />
				<Route path="/" element={LandingPage} />
				<Route
					path="/naming-experiments"
					element={<NamingExperimentSection />}
				/>
				<Route
					path="/applying-the-power-of-ai-to-naming"
					element={<PowerOfNaming />}
				/>
				<Route path="/naming-the-next-generation" element={<NamingNG />} />
				<Route
					path="/naming"
					element={<NamingSection text={NAMING_STRINGS} />}
				/>
				<Route path="/listening-live" element={<ListeningLive />} />
				<Route
					path="/can-ai-be-used-to-better-understand-humans"
					element={<AIHumans />}
				/>
				<Route
					path="/from-listening-to-conversing-consumers-reddit-and-jtbd"
					element={<ListeningTC />}
				/>
				<Route
					path="/replicating-human-sentiment"
					element={<ReplicatingHumanSentiment />}
				/>
				<Route path="/lilah" element={<Lilah />} />
				<Route path="/ai-and-culture" element={<AIandCulture />} />
			</Routes>
		</div>
	);
}

export default App;
