import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

const PBlockContainer = styled(motion.section)`
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background-image: url(${(props) => props.$backgroundImage});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  z-index: 99999 !important;
  @media screen and (min-height: 900px) {
    min-height: 200vh;
  }
`;

const PBlockContentWrapper = styled.div`
  min-height: 100vh;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8rem;
`;

const RegenerateBadge = styled.div.attrs({
  className: "regenerateBadge",
})``;

const Demo = (props) => {
  const [step, setStep] = useState(0);
  const [show, setShow] = useState(true);
  //   const [glitchVisible, setGlitchVisible] = useState(false);

  // Adjustments ram ===================
  const [isDemoActive, setIsDemoActive] = useState(false);
  const scrollToRef = useRef(null);
  useEffect(() => {
    const mainNav = document.querySelector(".mainNav");
    const body = document.querySelector("body");
    const root = document.querySelector("#root");
    if (isDemoActive) {
      root.style.height = "100vh";

      setTimeout(() => {
        body.style.overflowY = "hidden";
        root.style.overflow = "hidden";
        mainNav.style.top = "-200px";
        scrollToRef.current?.scrollIntoView();
      }, 0);
    } else if (root.style.height === "100vh") {
      mainNav.style.top = "";
      body.style.overflow = "";
      root.style.overflow = "";
      root.style.height = "";
      scrollToRef.current?.scrollIntoView();
    } else {
      body.style.overflow = "";
      root.style.overflow = "";
      root.style.height = "";
    }
  }, [isDemoActive]);

  const opts = [
    {
      label: "Crafty Weenies",
      description:
        "Unleash your taste buds with artisanal hot dogs at Crafty Weenies. Experience a world of flavors, from classic comfort dogs to inventive, gourmet creations that'll satisfy your cravings like never before.",
    },
    {
      label: "Savvy Franks",
      description:
        "Discover the art of the hot dog at Savvy Franks. With a menu full of thoughtfully crafted franks, this gourmet hotspot elevates the classic hot dog to a new level of sophistication and flavor.",
    },
    {
      label: "Doggbite",
      description:
        "Sink your teeth into the ultimate hot dog experience at Doggbite. These gourmet dogs are designed to delight with their mouthwatering combinations and bold flavors that will leave you craving more.",
    },
    {
      label: "Gourmaust",
      description:
        "At Gourmaust, hot dogs meet haute cuisine. Indulge in a selection of premium hot dogs made with the finest ingredients and innovative toppings, setting the bar for gourmet hot dog perfection.",
    },
    {
      label: "Dog Days Delight",
      description:
        "Step into a world of doggone deliciousness at Dog Days Delight, Savor ery bit of their delectable hot dogs, each bursting with unique toppings and quality ingredients, making every day a delightful dog day.",
    },
    {
      label: "DoggieDaze",
      description:
        "Embark on a flavor adventure at DoggieDaze, where gourmet hot dogs take center stage. These creatively curated dogs will transport your taste buds to new heights of delight, leaving you in a state of pure doggie daze.",
    },
  ];

  const [concept, setConcept] = useState(0);

  const setDemo = (v) => {
    setStep(0);
    setShow(true);
    props.onSetShowDemo(v);
  };

  const [isTryingAnother, setIsTryingAnother] = useState(false);
  useEffect(() => {
    if (step == 1 || step == 2) {
      if (isTryingAnother) {
        setShow(false);
        return () => {
          setShow(true);
          setIsTryingAnother(false);
        };
      } else {
        const timeout = setTimeout(() => setShow(false), 1000);
        return () => {
          clearTimeout(timeout);
          setShow(true);
        };
      }
    } else {
      setShow(true);
    }
  }, [step, isTryingAnother]);

  const GlitchImage = (
    <PBlockContainer
      $backgroundImage={step === 2 ? "images/rege.jpg" : ""}
      style={{ zIndex: 2 }}
    >
      <PBlockContentWrapper>
        <RegenerateBadge>{"</sourcing generated content/.>"}</RegenerateBadge>
      </PBlockContentWrapper>
    </PBlockContainer>
  );
  const [showGlitchOnStep1, setShowGlitchOnStep1] = useState(true);

  const changeConcept = (val) => {
    setConcept(val);
    setTimeout(() => {
      setStep(2);
    }, 100);
  };

  const _getBackground = () => {
    if (window.innerWidth <= 768) {
      return (
        "url(/images/hotdog/mob/hotdog_" +
        (Math.floor(Math.random() * 21) + 1) +
        ".webp) no-repeat center center / cover"
      );
    } else {
      return (
        "url(/images/hotdog/hotdog_" +
        (Math.floor(Math.random() * 33) + 1) +
        ".webp) no-repeat center center / cover"
      );
    }
  };

  return (
    <>
      {(step == 1 || step == 2) &&
      props.showDemo &&
      show &&
      (step != 1 || showGlitchOnStep1)
        ? GlitchImage
        : null}
      <div ref={scrollToRef} className={props.showDemo ? "demo-content" : ""}>
        {!props.showDemo ? (
          <div className="interactionContent">
            <h3>Let's</h3>
            <h3>Play</h3>
            <a
              className="gradientButton mt-64px"
              onClick={() => {
                setDemo(true);
                setIsDemoActive(true);
                setShowGlitchOnStep1(true);
              }}
            >
              Start Demo
            </a>
          </div>
        ) : null}

        {step === 0 && props.showDemo ? (
          <div className="interactionContent">
            <h2>OK! Let’s name a gourmet hot dog restaurant.</h2>
            <p>*Not a live AI demonstration, for explanation only</p>
            <a className="gradientButton mt-64px" onClick={() => setStep(1)}>
              Generate names{" "}
            </a>
          </div>
        ) : null}

        {step === 1 && props.showDemo ? (
          <>
            {!show ? (
              <div className="interactionContent">
                <h4>
                  Hot Dog! Here’s some ideas. Pick a favorite and let’s see some
                  concept art for your new business...
                </h4>
                <div className="buttons">
                  <a
                    className="gradientButton"
                    onClick={() => changeConcept(0)}
                  >
                    Crafty Weenies
                  </a>
                  <a
                    className="gradientButton"
                    onClick={() => changeConcept(1)}
                  >
                    savvy franks
                  </a>
                  <a
                    className="gradientButton"
                    onClick={() => changeConcept(2)}
                  >
                    Doggbite
                  </a>
                  <a
                    className="gradientButton"
                    onClick={() => changeConcept(3)}
                  >
                    gourmaust
                  </a>
                  <a
                    className="gradientButton"
                    onClick={() => changeConcept(4)}
                  >
                    Dog Days Delight
                  </a>
                  <a
                    className="gradientButton"
                    onClick={() => changeConcept(5)}
                  >
                    DoggieDaze
                  </a>
                </div>
              </div>
            ) : null}
          </>
        ) : null}

        {step == 2 && props.showDemo ? (
          <div className="interactionContent">
            <div className="result-demo">
              <h3>{opts[concept].label}</h3>
              <p>{opts[concept].description}</p>
              <div className="backImageContent">
                <div
                  className="backImage"
                  style={{ background: _getBackground() }}
                ></div>
              </div>
            </div>
            <a
              className="gradientButton tryAnotherButton"
              onClick={() => {
                setStep(1);
                setShowGlitchOnStep1(false);
                setIsTryingAnother(true);
              }}
            >
              Try another?
            </a>
          </div>
        ) : null}

        {props.showDemo && (
          <svg
            width="75"
            height="75"
            viewBox="0 0 75 75"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => {
              setDemo(false);
              setIsDemoActive(false);
            }}
            className="hideDemo"
          >
            <circle cx="37" cy="37" r="37" fill="#ddf1ff" />
            <rect
              x="1"
              y="1"
              width="73"
              height="73"
              rx="36.5"
              stroke="black"
              strokeWidth="2"
            />
            <path
              d="M42.2373 42.78L32.7373 38.6V37.48L42.2373 32.74V34.52L34.9573 37.96L42.2373 41V42.78Z"
              fill="black"
            />
          </svg>
        )}
      </div>
    </>
  );
};

export default Demo;
