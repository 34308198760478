import React, { useRef, useState, useEffect } from "react";
import "./Robots.css";
import TextStrings from "./textStrings";
import { motion, useScroll, useTransform } from "framer-motion";
import { useMediaQuery } from "@react-hook/media-query";

// const RobotOne = styled.img.attrs({
//   src: process.env.PUBLIC_URL + "/images/robots/robotott-1.webp",
//   className: "robotOne",
// })``;

// const RobotTwo = styled.img.attrs({
//   src: process.env.PUBLIC_URL + "/images/robots/robotott-2.webp",
//   className: "robotTwo",
// })``;

// const RobotThree = styled.img.attrs({
//   src: process.env.PUBLIC_URL + "/images/robots/robotott-3.webp",
//   className: "robotThree",
// })``;

// const RobotBeach = styled.img.attrs({
//   src: process.env.PUBLIC_URL + "/images/robots/robotbeach.webp",
//   className: "robotBeach",
// })``;

const RobotsModule = () => {
  const RobotBlockOne = () => {
    /// SCROLL ANIMATIONS ///
    const ref = useRef(null);
    const { scrollYProgress } = useScroll({
      target: ref,
      offset: ["end end", "start start"],
    });

    const [itemWidth, setItemWidth] = useState(-1);

    if (ref.current) {
      setItemWidth(window.getComputedStyle(ref.current).width);
    }

    const robotOneY = useTransform(
      scrollYProgress,
      [0, 1],
      itemWidth >= 600 ? [-900, -120] : [-240, -55],
      {
        clamp: false,
      }
    );

    return (
      <div className="robotBlockOne">
        <div className="robotsWrapper">
          <div className="negativeRow">
            <h2 className="robotHeading">{TextStrings.BlockOne.HeadOne}</h2>
            <h2 className="robotHeading">{TextStrings.BlockOne.HeadTwo}</h2>
            <h2 className="robotHeading">{TextStrings.BlockOne.HeadThree}</h2>
          </div>
        </div>
        <motion.img
          ref={ref}
          className="robotOne"
          src={process.env.PUBLIC_URL + "/images/robots/robotott-1.webp"}
          style={{
            top: robotOneY,
          }}
        />
      </div>
    );
  };

  // const RobotBlockTwo = () => {
  //   /// SCROLL ANIMATIONS ///
  //   const ref = useRef(null);
  //   const { scrollYProgress } = useScroll({
  //     target: ref,
  //     offset: ["center end", "end end"],
  //   });

  //   const [robotTwoRightStyle, setRobotTwoRightStyle] = useState("-12%");

  //   useEffect(() => {
  //     const handleResize = () => {
  //       if (window.innerWidth <= 480) {
  //         setRobotTwoRightStyle("-23%");
  //       } else if (window.innerWidth <= 768) {
  //         setRobotTwoRightStyle("-2%");
  //       } else {
  //         setRobotTwoRightStyle("-12%");
  //       }
  //     };

  //     handleResize();
  //     window.addEventListener("resize", handleResize);

  //     return () => {
  //       window.removeEventListener("resize", handleResize);
  //     };
  //   }, []);

  //   const robotTwoRight = useTransform(
  //     scrollYProgress,
  //     [0, 0.5, 0.5, 1],
  //     ["-50%", "-12%", "-12%", "-80%"],
  //     { ease: cubicBezier(0.3, 0.27, 0.07, 1.64) }
  //   );
  //   const robotTwoTop = useTransform(scrollYProgress, [0, 1], ["140%", "20%"], {
  //     ease: cubicBezier(0.02, 0.01, 0.21, 1),
  //   });

  //   return (
  //     <div className="robotBlockTwo grayHeadings" ref={ref}>
  //       <div className="robotsWrapper">
  //         <div className="robotTextWrapper">
  //           <p>{TextStrings.BlockTwo.TextOne}</p>
  //           <p>{TextStrings.BlockTwo.TextTwo}</p>
  //         </div>
  //         <h2 className="robotHeading">{TextStrings.BlockTwo.HeadOne}</h2>
  //         <h2 className="robotHeading">{TextStrings.BlockTwo.HeadTwo}</h2>
  //         <h2 className="robotHeading">{TextStrings.BlockTwo.HeadThree}</h2>
  //         <div className="robotTextWrapper">
  //           <p>{TextStrings.BlockTwo.TextThree}</p>
  //         </div>
  //       </div>
  //       <motion.img
  //         className="robotTwo"
  //         src={process.env.PUBLIC_URL + "/images/robots/robotott-2.webp"}
  //         style={{
  //           top: robotTwoTop,
  //           right: robotTwoRight,
  //         }}
  //       />
  //     </div>
  //   );
  // };

  const RobotBlockThree = () => {
    /// SCROLL ANIMATIONS ///
    const ref = useRef(null);
    const { scrollYProgress } = useScroll({
      target: ref,
      offset: ["0 1", ".8 1"],
    });

    // const [robotThreeLeftStyle, setRobotThreeLeftStyle] = useState("-12%");

    // useEffect(() => {
    //   const handleResize = () => {
    //     if (window.innerWidth <= 480) {
    //       setRobotThreeLeftStyle("-23%");
    //     } else {
    //       setRobotThreeLeftStyle("-12%");
    //     }
    //   };

    //   handleResize();
    //   window.addEventListener("resize", handleResize);

    //   return () => {
    //     window.removeEventListener("resize", handleResize);
    //   };
    // }, []);

    const is768 = useMediaQuery("only screen and (max-width: 768px)");
    const is640 = useMediaQuery("only screen and (max-width: 640px)");

    const robotThreeLeft = useTransform(
      scrollYProgress,
      [0, 1],
      ["-50%", is640 ? "-20%" : is768 ? "-23%" : "-12%"],
      { clamp: false }
    );

    return (
      <div className="robotBlockThree grayHeadings">
        <div className="robotsWrapper">
          <div className="headingBlockOne">
            <h2 className="robotHeading">{TextStrings.BlockThree.HeadOne}</h2>
            <h2 className="robotHeading">{TextStrings.BlockThree.HeadTwo}</h2>
            <h2 className="robotHeading">{TextStrings.BlockThree.HeadThree}</h2>
          </div>
          <div className="robotTextWrapper">
            <ul>
              {TextStrings.BlockThree.ListOne.split("\n").map((item, index) => (
                <li key={index}>{item.trim()}</li>
              ))}
            </ul>
          </div>
          <div className="headingBlockTwo">
            <h2 className="robotHeading">{TextStrings.BlockThree.HeadFour}</h2>
            <h2 className="robotHeading">{TextStrings.BlockThree.HeadTwo}</h2>
            <h2 className="robotHeading">{TextStrings.BlockThree.HeadThree}</h2>
          </div>
        </div>
        <motion.img
          ref={ref}
          className="robotThree"
          src={process.env.PUBLIC_URL + "/images/robots/robotott-3.webp"}
          style={{
            left: robotThreeLeft,
          }}
        />
      </div>
    );
  };

  const RobotBlockFour = () => {
    /// SCROLL ANIMATIONS ///
    const ref = useRef(null);
    const { scrollYProgress } = useScroll({
      target: ref,
      offset: ["end end", "start start"],
    });
    const robotY = useTransform(scrollYProgress, [0, 1], ["100%", "0%"], {
      clamp: false,
    });
    return (
      <div className="robotBlockFour">
        <div className="robotsWrapper">
          <div className="robotTextWrapper" ref={ref}>
            <p>{TextStrings.BlockFour.TextOne}</p>
            <p>{TextStrings.BlockFour.TextTwo}</p>
          </div>
        </div>
        <motion.img
          className="robotBeach"
          src={process.env.PUBLIC_URL + "/images/robots/robotbeach.webp"}
          style={{
            y: robotY,
          }}
        />
      </div>
    );
  };

  const scrollWrapper = useRef(null);
  const { scrollYProgress } = useScroll({
    target: scrollWrapper,
    offset: ["start end", "end end"],
  });

  const is768 = useMediaQuery("only screen and (max-width: 768px)");
  const is480 = useMediaQuery("only screen and (max-width: 480px)");

  const robotTwoRightStyle = is480 ? "-12%" : is768 ? "-10%" : "-12%";

  const robotTwoRight = useTransform(
    scrollYProgress,
    [0, is480 ? 0.7 : 0.5, is480 ? 0.7 : 0.5, 1],
    ["-100%", robotTwoRightStyle, robotTwoRightStyle, robotTwoRightStyle],
    {
      clamp: false,
    }
  );

  // const robotTwoRight = useTransform(
  //   scrollYProgress,
  //   [0, 0.5, 0.5, 1],
  //   [
  //     "-50%",
  //     `${is768 ? "-5%" : "-12%"}`,
  //     `${is768 ? "-5%" : "-12%"}`,
  //     `${is480 ? "-20%" : "-80%"}`,
  //   ],
  //   { ease: cubicBezier(0.3, 0.27, 0.07, 1.64) }
  // );
  // const robotTwoTop = useTransform(
  //   scrollYProgress,
  //   [0, 1],
  //   ["160%", `${is480 ? "50%" : "10%"}`],
  //   {
  //     ease: cubicBezier(0.02, 0.01, 0.21, 1),
  //   }
  // );

  return (
    <section className="robotsSection" ref={scrollWrapper}>
      <RobotBlockOne />
      {/*<RobotBlockTwo />*/}

      <div className="robotBlockTwo grayHeadings">
        <div className="robotsWrapper">
          <div className="robotTextWrapper">
            <p>{TextStrings.BlockTwo.TextOne}</p>
            <p>{TextStrings.BlockTwo.TextTwo}</p>
          </div>
          <h2 className="robotHeading">{TextStrings.BlockTwo.HeadOne}</h2>
          <h2 className="robotHeading">{TextStrings.BlockTwo.HeadTwo}</h2>
          <h2 className="robotHeading">{TextStrings.BlockTwo.HeadThree}</h2>
          <div className="robotTextWrapper">
            <p>{TextStrings.BlockTwo.TextThree}</p>
          </div>
        </div>
        <motion.img
          className="robotTwo"
          src={process.env.PUBLIC_URL + "/images/robots/robotott-2.webp"}
          style={{
            right: robotTwoRight,
          }}
        />
      </div>

      <RobotBlockThree />
      <RobotBlockFour />
    </section>
  );
};
export { RobotsModule };
