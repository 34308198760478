import { Canvas } from "@react-three/fiber";
import { PointLight } from "three";
import { OrbitControls, useGLTF } from "@react-three/drei";
import { Suspense } from "react";
import { models } from "../constants/models";

const QuillCanvas = ({ active, is480 }) => {
  const model = useGLTF(models.quill);
  const light = new PointLight("#FFFFFF", 20, 0, 0.5);
  light.position.set(0, 0, 0);
  return (
    <div
      className="canvas-wrapper"
      style={{
        width: "100%",
        position: "relative",
        display: !active ? "none" : "",
      }}
    >
      <Canvas
        frameloop="demand"
        camera={{ position: [0, 1, -8], fov: 35 }}
        onCreated={({ camera, scene }) => {
          scene.add(camera);
          //scene.add(light);
        }}
      >
        <ambientLight intensity={8} />
        <Suspense fallback="Loading...">
          <OrbitControls
            autoRotate
            enableZoom={false}
            target={[0, 0, 0]}
            enabled={active}
          />
          <primitive
            object={model.scene}
            scale={20}
            position={[0, -1, 0]}
            rotation={[0, 0, 0]}
          />
        </Suspense>
      </Canvas>
    </div>
  );
};

export default QuillCanvas;
